<template>
  <div :class="['v-input-number', readonly && 'v-input-number--readonly']">
    <button v-if="!readonly" class="v-input-number__decrease" type="button" @click="decrement">
      <i class="v-input-number__icon-minus"></i>
    </button>
    <input
      v-model.number="localValue"
      type="text"
      role="spinbutton"
      inputmode="numeric"
      :maxlength="maxlength"
      :readonly="readonly"
      class="v-input-number__elem"
      @keydown="onKeydown($event)"
      @input="$emit('input', localValue)"
    />
    <button v-if="!readonly" class="v-input-number__increase" type="button" @click="increment">
      <i class="v-input-number__icon-plus"></i>
    </button>
  </div>
</template>

<script>
import { BACKSPACE_CODE, ARROW_DOWN_CODE, TAB_CODE, ARROW_UP_CODE, KEY_CODES_NUMBER } from '@/constants/keyCodes'

export default {
  name: 'VInputNumber',
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    maxlength: {
      type: Number,
      default: 3
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: 0
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.localValue = Number(value)
      }
    }
  },
  methods: {
    increment() {
      this.localValue += 1
      this.$emit('input', this.localValue)
    },
    decrement() {
      this.localValue -= 1
      this.$emit('input', this.localValue)
    },

    onKeydown(event) {
      const { key } = event
      if (!KEY_CODES_NUMBER.includes(key) && key !== BACKSPACE_CODE && key !== TAB_CODE) {
        if (key !== 'v' || !(event.ctrlKey || event.metaKey)) {
          event.preventDefault()
        }
        event.stopPropagation()
      }

      if (key === ARROW_UP_CODE) {
        this.increment()
        event.preventDefault()
      }
      if (key === ARROW_DOWN_CODE) {
        this.decrement()
        event.preventDefault()
      }
    }
  }
}
</script>
