<template>
  <v-dialog :visible.sync="localVisible" :title="title">
    <validation-observer v-slot="{ handleSubmit }" class="edit-dialog-user">
      <v-form body-scroll-lock-ignore @submit.prevent="handleSubmit(onSubmit)">
        <template #default>
          <v-form-row>
            <v-form-field separate-label>
              <v-input-file-image v-model="user.photo" class="edit-dialog-user__image" @set-file="setImage($event)">
                Фото
              </v-input-file-image>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ТИП ПОЛЬЗОВАТЕЛЯ" rules="required" :error-message="errors.role">
              <v-tabs v-model="user.role" class="edit-dialog-user__role-tabs" primary>
                <v-tab v-for="(role, index) of $options.USER_TYPES_TABS" :key="index" :name="role.value">
                  {{ role.text }}
                </v-tab>
              </v-tabs>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ИМЯ ПОЛЬЗОВАТЕЛЯ" rules="required" :error-message="errors.name">
              <template #default="{ validationErrors }">
                <v-input v-model="user.name" :is-error="!!validationErrors.length" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ДОЛЖНОСТЬ" rules="required">
              <template #default="{ validationErrors }">
                <v-input v-model="user.position" :is-error="!!validationErrors.length" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ТЕЛЕФОН" rules="required" :error-message="errors.phone">
              <template #default="{ validationErrors }">
                <v-input-phone v-model="user.phone" :is-error="!!validationErrors.length" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="EMAIL" rules="required|email" :error-message="errors.email">
              <template #default="{ validationErrors }">
                <v-input v-model="user.email" :is-error="!!validationErrors.length" type="email" />
              </template>
            </v-form-field>
          </v-form-row>
        </template>

        <template #footer>
          <v-button primary type="submit">{{ buttonText }}</v-button>
        </template>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VButton from '@/components/common/VButton.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VInputFileImage from '@/components/common/VInputFileImage.vue'
import { cloneDeep, getFirstErrorForFields, getPatchedFields } from '@/utils/common'
import { loadingService } from '@/services/loading'
import { authService } from '@/services/http'
import VTab from '@/components/common/VTab.vue'
import VTabs from '@/components/common/VTabs.vue'
import { USER_STATUS_AGENT, USER_STATUS_ADMIN } from '@/constants/userStatuses'

export default {
  USER_TYPES_TABS: { USER_STATUS_ADMIN: { ...USER_STATUS_ADMIN, text: 'Администратор' }, USER_STATUS_AGENT },
  name: 'UserEditDialog',
  components: { VTabs, VTab, VInputFileImage, VInputPhone, VDialog, VFormField, VFormRow, VButton, VInput, VForm },
  props: {
    visible: { type: Boolean, required: true },
    userData: { type: Object, default: () => {} }
  },
  data() {
    return {
      user: {
        photo: null,
        name: '',
        phone: '',
        email: '',
        position: '',
        role: ''
      },
      loading: false,
      errors: {}
    }
  },
  computed: {
    isCreation() {
      return !(this.userData && this.userData.id)
    },
    title() {
      if (this.userData?.isPending) {
        return 'Редактировать приглашение'
      }
      return this.isCreation ? 'Новый пользователь' : 'Редактировать пользователя'
    },
    buttonText() {
      return this.isCreation ? 'Пригласить пользователя' : 'Сохранить'
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    userData: {
      immediate: true,
      handler() {
        this.resetLocalUserData()
      }
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.resetLocalUserData()
      }
    },
    loading(val) {
      loadingService.setGlobalLoading(val)
    }
  },
  methods: {
    resetLocalUserData() {
      const emptyUser = {
        photo: '',
        name: '',
        phone: '',
        email: '',
        position: '',
        role: ''
      }
      this.user = { ...emptyUser, ...cloneDeep(this.userData) }
      this.errors = {}
    },
    setImage(image) {
      this.user.photo = image
    },
    createUser(userData) {
      this.loading = true
      return authService.createUser(userData).finally(() => {
        this.loading = false
      })
    },
    updateUser({ id, ...userData }) {
      this.loading = true
      return authService.updateUser(id, userData).finally(() => {
        this.loading = false
      })
    },
    onSubmit() {
      let newUserData
      if (this.isCreation) {
        newUserData = { ...this.user }
      } else {
        newUserData = { id: this.userData.id, ...getPatchedFields(this.userData, this.user) }
      }
      const action = this.isCreation ? this.createUser : this.updateUser
      action(newUserData)
        .then(() => {
          if (this.userData?.isPending) {
            authService.setPassword(this.userData.id, this.user.email)
          }
          this.$emit('submitted')
          this.localVisible = false
        })
        .catch(error => {
          this.errors = getFirstErrorForFields(error.details)
        })
    }
  }
}
</script>
