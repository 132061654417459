<template>
  <v-table-wrapper
    :infinite-id="infiniteId"
    :list="list"
    :infinite-loading="infiniteLoading"
    :table-height="tableHeight"
    with-select
    only-single-select
    class="users-table"
    v-on="$listeners"
  >
    <template #customColumns>
      <el-table-column prop="created_at" label="Добавлен" class-name="users-table__cell--date" width="100">
        <template #default="{row:{dateJoined}}">
          {{ formatDate(dateJoined) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="user"
        class-name="users-table__cell--user"
        label="Пользователь"
        width="200"
        show-overflow-tooltip
      >
        <template #default="{row:{name, photo}}">
          <v-agent :photo="photo">
            {{ name }}
          </v-agent>
        </template>
      </el-table-column>
      <el-table-column prop="userStatus" label="Статус" class-name="users-table__cell--status" width="134">
        <template #default="{row:{role, isPending }}">
          <v-tag-group
            :value="isPending ? $options.USER_STATUS_INVITED.value : role"
            :group="$options.USER_STATUSES_GROUP"
          >
            <template #default="{ color, text }">
              <v-tag :color="color"> {{ text }}</v-tag>
            </template>
          </v-tag-group>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="Телефон" class-name="users-table__cell--phone" width="143">
        <template #default="{row:{ phone }}">
          <v-phones-popover :phones="[phone]"></v-phones-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        class-name="users-table__cell--email"
        min-width="200"
        show-overflow-tooltip
      >
        <template #default="{row:{ email }}">
          {{ email }}
        </template>
      </el-table-column>
      <el-table-column prop="results" label="Результаты" class-name="users-table__cell--results" min-width="110">
        <template #default="{row}">
          <div
            v-if="row.role === $options.ROLE_AGENT && !row.isPending"
            class="users-table__results"
            @click="openAgentPlan(row)"
          >
            <el-progress
              type="circle"
              :percentage="(row.agentProfile && getPercentage(row.agentProfile.currentPlan, 'show')) || 0"
              :color="$options.variables.agentShowsProgressColor"
              :width="32"
              :stroke-width="2"
            ></el-progress>
            <el-progress
              type="circle"
              :percentage="(row.agentProfile && getPercentage(row.agentProfile.currentPlan, 'advert')) || 0"
              :color="$options.variables.agentObjectsProgressColor"
              :width="32"
              :stroke-width="2"
            ></el-progress>
          </div>
        </template>
      </el-table-column>
    </template>
  </v-table-wrapper>
</template>

<script>
import VTableWrapper from '@/components/common/VTableWrapper.vue'
import VAgent from '@/components/common/VAgent.vue'
import { formatDate } from '@/utils/formatters'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { USER_STATUSES_GROUP, USER_STATUS_INVITED } from '@/constants/userStatuses'
import VTag from '@/components/common/VTag.vue'
import VTagGroup from '@/components/common/VTagGroup.vue'
import VPhonesPopover from '@/components/common/VPhonesPopover.vue'
import variables from '@/assets/scss/index.scss'
import { getPercentage } from '@/utils/common'
import { ROLE_AGENT } from '@/constants/roles'

export default {
  ROLE_AGENT,
  variables,
  USER_STATUSES_GROUP,
  USER_STATUS_INVITED,
  name: 'UsersTable',
  components: {
    VPhonesPopover,
    VTableWrapper,
    VTagGroup,
    VTag,
    VAgent
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableHeight: {
      type: Number,
      default: 500
    },
    infiniteId: {
      type: Number,
      required: true
    },
    infiniteLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    })
  },
  methods: {
    getPercentage(plan, type) {
      return type === 'advert'
        ? getPercentage(plan.actualAdvertsCount, plan.advertsCount)
        : getPercentage(plan.actualShowsCount, plan.showsCount)
    },
    openAgentPlan(agent) {
      this.$emit('add-plan', agent)
    },
    formatDate(date) {
      return formatDate(date, '.')
    }
  }
}
</script>

<style lang="scss">
.users-table {
  &__results {
    gap: 8px;
    display: flex;
    .el-progress__text {
      font-size: 8px !important;
    }
  }
}
</style>
