var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"visible":_vm.localVisible,"title":_vm.title},on:{"update:visible":function($event){_vm.localVisible=$event}}},[_c('validation-observer',{staticClass:"edit-dialog-user",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"body-scroll-lock-ignore":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',[_c('v-form-field',{attrs:{"separate-label":""}},[_c('v-input-file-image',{staticClass:"edit-dialog-user__image",on:{"set-file":function($event){return _vm.setImage($event)}},model:{value:(_vm.user.photo),callback:function ($$v) {_vm.$set(_vm.user, "photo", $$v)},expression:"user.photo"}},[_vm._v(" Фото ")])],1)],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТИП ПОЛЬЗОВАТЕЛЯ","rules":"required","error-message":_vm.errors.role}},[_c('v-tabs',{staticClass:"edit-dialog-user__role-tabs",attrs:{"primary":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},_vm._l((_vm.$options.USER_TYPES_TABS),function(role,index){return _c('v-tab',{key:index,attrs:{"name":role.value}},[_vm._v(" "+_vm._s(role.text)+" ")])}),1)],1)],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИМЯ ПОЛЬЗОВАТЕЛЯ","rules":"required","error-message":_vm.errors.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ДОЛЖНОСТЬ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.user.position),callback:function ($$v) {_vm.$set(_vm.user, "position", $$v)},expression:"user.position"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТЕЛЕФОН","rules":"required","error-message":_vm.errors.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-phone',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"EMAIL","rules":"required|email","error-message":_vm.errors.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-button',{attrs:{"primary":"","type":"submit"}},[_vm._v(_vm._s(_vm.buttonText))])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }