import { BLUE, RED, LIGHT_GRAY } from '@/constants/tagColors'
import { ROLE_AGENT, ROLE_ADMIN } from '@/constants/roles'

export const USER_STATUS_AGENT = { text: 'Агент', color: BLUE, value: ROLE_AGENT }
export const USER_STATUS_ADMIN = { text: 'Админ', color: RED, value: ROLE_ADMIN }
export const USER_STATUS_INVITED = { text: 'Приглашён', color: LIGHT_GRAY, value: 'invited' }

export const USER_STATUSES_GROUP = {
  [USER_STATUS_AGENT.value]: USER_STATUS_AGENT,
  [USER_STATUS_ADMIN.value]: USER_STATUS_ADMIN,
  [USER_STATUS_INVITED.value]: USER_STATUS_INVITED
}
