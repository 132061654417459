<template>
  <v-bulk-edit-dialog class="user-bulk-edit-dialog" @close="close" @reset="updateUserEditModel(user)">
    <template #itemsCount>
      {{ isPendingUserEdit ? 'Приглашение' : 'Пользователь' }}
    </template>
    <template #body>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form class="user-bulk-edit-dialog__form" @submit.prevent="handleSubmit(onSubmit)">
          <template #default>
            <v-form-row>
              <v-form-field label="ИМЯ ПОЛЬЗОВАТЕЛЯ" rules="required" :error-message="errors.name">
                <template #default="{ validationErrors }">
                  <v-input v-model="userEditModel.name" type="text" :is-error="!!validationErrors.length" />
                </template>
              </v-form-field>
            </v-form-row>
            <v-form-row>
              <v-form-field label="ТЕЛЕФОН ПОЛЬЗОВАТЕЛЯ" rules="required" :error-message="errors.phone">
                <template #default="{ validationErrors }">
                  <v-input-phone v-model="userEditModel.phone" :is-error="!!validationErrors.length" />
                </template>
              </v-form-field>
            </v-form-row>
            <v-form-row>
              <v-form-field label="ПОЧТА ПОЛЬЗОВАТЕЛЯ" rules="required|email" :error-message="errors.email">
                <template #default="{ validationErrors }">
                  <v-input v-model="userEditModel.email" :is-error="!!validationErrors.length" type="email" />
                </template>
              </v-form-field>
            </v-form-row>

            <v-form-row>
              <v-form-field label="ТИП ПОЛЬЗОВАТЕЛЯ" rules="required" :error-message="errors.role">
                <v-select
                  v-model="userEditModel.role"
                  :clearable="false"
                  :reduce="userType => userType.value"
                  label="text"
                  append-to-body
                  :options="$options.USER_TYPES"
                />
              </v-form-field>
            </v-form-row>
            <v-form-row>
              <v-form-field label="ДОЛЖНОСТЬ" rules="required" :error-message="errors.position">
                <template #default="{ validationErrors }">
                  <v-input v-model="userEditModel.position" :is-error="!!validationErrors.length" />
                </template>
              </v-form-field>
            </v-form-row>
            <v-form-row>
              <v-form-field separate-label label="ФОТОГРАФИЯ" :error-message="errors.photo">
                <template #default="{ validationErrors, validate }">
                  <v-input-file-image
                    v-model="userEditModel.photo"
                    with-drop-zone
                    :is-error="!!validationErrors.length"
                    @set-file="validate($event)"
                  />
                </template>
              </v-form-field>
            </v-form-row>
          </template>
          <template #footer>
            <template v-if="isPendingUserEdit">
              <v-button class="user-bulk-edit-dialog__form-action" @click="cancelInvite">Отменить приглашение</v-button>
              <v-button class="user-bulk-edit-dialog__form-action" primary type="submit">Отправить повторно</v-button>
            </template>
            <template v-else>
              <v-button class="user-bulk-edit-dialog__form-action" @click="close">Отменить</v-button>
              <v-button class="user-bulk-edit-dialog__form-action" primary type="submit">Обновить</v-button>
            </template>
          </template>
        </v-form>
      </validation-observer>
    </template>
    <template v-if="!isPendingUserEdit" #footer>
      <v-button v-if="isUsersActive" class="user-bulk-edit-dialog__action" is-text @click="archive"
        >Архивировать пользователя</v-button
      >
      <v-button v-else class="user-bulk-edit-dialog__action" is-text @click="activate"
        >Активировать пользователя</v-button
      >
    </template>
  </v-bulk-edit-dialog>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import VForm from '@/components/form/VForm.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSelect from '@/components/common/VSelect.vue'
import { cloneDeep, getFirstErrorForFields, getPatchedFields } from '@/utils/common'
import VBulkEditDialog from '@/components/common/VBulkEditDialog.vue'
import { USER_STATUS_ACTIVE } from '@/constants/statuses/users'
import { USER_STATUS_ADMIN, USER_STATUS_AGENT } from '@/constants/userStatuses'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import { authService } from '@/services/http'
import VInputFileImage from '@/components/common/VInputFileImage.vue'

export default {
  USER_TYPES: [{ ...USER_STATUS_ADMIN, text: 'Администратор' }, USER_STATUS_AGENT],

  name: 'UserBulkEditDialog',
  components: {
    VInputFileImage,
    VInputPhone,
    VInput,
    VBulkEditDialog,
    VSelect,
    VFormRow,
    VFormField,
    VForm,
    VButton
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      errors: {},
      userEditModel: {}
    }
  },
  computed: {
    isPendingUserEdit() {
      return this.user.isPending
    },
    userId() {
      return this.user.id
    },
    isUsersActive() {
      return this.$route.query.status === USER_STATUS_ACTIVE
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(newUserData) {
        this.updateUserEditModel(newUserData)
      }
    }
  },
  methods: {
    archive() {
      this.$emit('archive', this.userId)
    },
    activate() {
      this.$emit('activate', this.userId)
    },
    updateUserEditModel(model) {
      this.userEditModel = cloneDeep(model)
      this.errors = {}
    },
    close() {
      this.updateUserEditModel({})
      this.$emit('close')
    },
    cancelInvite() {
      this.$emit('cancel-invite', this.userId)
    },
    onSubmit() {
      const newUserData = getPatchedFields(this.user, this.userEditModel)

      authService
        .updateUser(this.userId, newUserData)
        .then(() => {
          if (this.isPendingUserEdit) {
            authService.setPassword(this.userId, this.userEditModel.email)
          }
        })
        .catch(error => {
          this.errors = getFirstErrorForFields(error.details)
        })
        .finally(() => {
          this.$emit('submitted')
          this.close()
        })
    }
  }
}
</script>
