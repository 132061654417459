<template>
  <v-dialog :visible.sync="localVisible" size="tiny">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form body-scroll-lock-ignore @submit.prevent="handleSubmit(onSubmit)">
        <template #default>
          <v-form-row class="v-agent-plan-dialog__row v-agent-plan-dialog__row--user-info">
            <v-form-field>
              <v-avatar size="large" :src="agentData.photo"></v-avatar>
            </v-form-field>
            <h3 class="v-agent-plan-dialog__name">{{ agentData.name }}</h3>
          </v-form-row>
          <v-form-row class="v-agent-plan-dialog__row v-agent-plan-dialog__row--selects">
            <v-form-field class="v-agent-plan-dialog__field">
              <v-select
                v-model="monthValue"
                append-to-body
                inputmode="none"
                :clearable="false"
                :options="monthsOptions"
                @input="fetchAgentPlan"
              />
            </v-form-field>
            <v-form-field class="v-agent-plan-dialog__field">
              <v-select
                v-model="plan.year"
                append-to-body
                inputmode="none"
                :clearable="false"
                :loading="yearsOptionsLoading"
                :options="yearsOptions"
                @input="fetchAgentPlan"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row class="v-agent-plan-dialog__row">
            <v-form-field label="План показов" separate-label label-size="medium" class="v-agent-plan-dialog__field">
              <v-input-number v-model="plan.showsCount" />
            </v-form-field>
            <v-form-field label="Факт" label-size="medium" class="v-agent-plan-dialog__field">
              <v-input-number v-model="plan.actualShowsCount" readonly />
            </v-form-field>
          </v-form-row>
          <v-form-row class="v-agent-plan-dialog__row">
            <v-form-field label="План объектов" separate-label label-size="medium" class="v-agent-plan-dialog__field">
              <v-input-number v-model="plan.advertsCount" />
            </v-form-field>
            <v-form-field label="Факт" label-size="medium" class="v-agent-plan-dialog__field">
              <v-input-number v-model="plan.actualAdvertsCount" readonly />
            </v-form-field>
          </v-form-row>
        </template>

        <template #footer>
          <v-button primary type="submit">Сохранить</v-button>
        </template>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VSelect from '@/components/common/VSelect.vue'
import VButton from '@/components/common/VButton.vue'
import VInputNumber from '@/components/common/VInputNumber.vue'
import VAvatar from '@/components/common/VAvatar.vue'
import { agentsService } from '@/services/http'
import { loadingService } from '@/services/loading'
import { MONTHS } from '@/utils/formatters'

export default {
  name: 'AgentPlanDialog',
  components: { VAvatar, VInputNumber, VDialog, VFormField, VFormRow, VButton, VForm, VSelect },
  props: {
    visible: { type: Boolean, required: true },
    agentData: { type: Object, required: true }
  },
  data() {
    return {
      loading: false,
      yearsOptionsLoading: false,
      yearsOptions: [],
      plan: {
        id: null,
        agent: null,
        month: null,
        year: null,
        advertsCount: 0,
        showsCount: 0,
        actualAdvertsCount: 0,
        actualShowsCount: 0
      }
    }
  },
  computed: {
    monthValue: {
      get() {
        return MONTHS[this.plan.month - 1]
      },

      set(obj) {
        this.plan.month = obj.value + 1
      }
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    monthsOptions() {
      return MONTHS.map((label, index) => ({ label, value: index }))
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setCurrentDate()
          this.fetchAgentYears()
          this.fetchAgentPlan()
        }
      }
    }
  },
  methods: {
    setCurrentDate() {
      const date = new Date()
      this.plan.month = date.getMonth() + 1
      this.plan.year = date.getFullYear()
    },

    fetchAgentYears() {
      this.yearsOptionsLoading = true
      return agentsService
        .selectYears(this.agentData.agentProfile.id)
        .then(res => {
          const { years } = res
          this.yearsOptions = years
        })
        .finally(() => {
          this.yearsOptionsLoading = false
        })
    },

    fetchAgentPlan() {
      this.loading = true
      const { month, year } = this.plan
      return agentsService
        .getPlan({ agent: this.agentData.agentProfile.id, month, year })
        .then(plan => {
          this.plan = plan
        })
        .finally(() => {
          this.loading = false
        })
    },

    onSubmit() {
      this.loading = true
      const { month, year, showsCount, advertsCount } = this.plan

      return agentsService.updatePlan(this.plan.id, { month, year, showsCount, advertsCount }).finally(() => {
        this.localVisible = false
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';
@import 'src/assets/scss/mixins';

.v-agent-plan-dialog {
  &__name {
    @include text-overflow(2);

    width: 57%;
    margin-left: 25px;
    font-size: 28px;
    font-weight: $--font-weight-bold;
  }

  &__field {
    width: 130px;

    @include phone {
      width: 100%;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__row {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;

    @include phone {
      justify-content: flex-start;
    }

    &--user-info {
      justify-content: flex-start;
      align-items: center;
    }

    &--selects {
      margin-bottom: 35px;
    }
  }
}
</style>
