var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-table-wrapper',_vm._g({staticClass:"users-table",attrs:{"infinite-id":_vm.infiniteId,"list":_vm.list,"infinite-loading":_vm.infiniteLoading,"table-height":_vm.tableHeight,"with-select":"","only-single-select":""},scopedSlots:_vm._u([{key:"customColumns",fn:function(){return [_c('el-table-column',{attrs:{"prop":"created_at","label":"Добавлен","class-name":"users-table__cell--date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dateJoined = ref.row.dateJoined;
return [_vm._v(" "+_vm._s(_vm.formatDate(dateJoined))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"user","class-name":"users-table__cell--user","label":"Пользователь","width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var name = ref_row.name;
var photo = ref_row.photo;
return [_c('v-agent',{attrs:{"photo":photo}},[_vm._v(" "+_vm._s(name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"userStatus","label":"Статус","class-name":"users-table__cell--status","width":"134"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var role = ref_row.role;
var isPending = ref_row.isPending;
return [_c('v-tag-group',{attrs:{"value":isPending ? _vm.$options.USER_STATUS_INVITED.value : role,"group":_vm.$options.USER_STATUSES_GROUP},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('v-tag',{attrs:{"color":color}},[_vm._v(" "+_vm._s(text))])]}}],null,true)})]}}])}),_c('el-table-column',{attrs:{"prop":"phone","label":"Телефон","class-name":"users-table__cell--phone","width":"143"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var phone = ref.row.phone;
return [_c('v-phones-popover',{attrs:{"phones":[phone]}})]}}])}),_c('el-table-column',{attrs:{"prop":"email","label":"Email","class-name":"users-table__cell--email","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var email = ref.row.email;
return [_vm._v(" "+_vm._s(email)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"results","label":"Результаты","class-name":"users-table__cell--results","min-width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.role === _vm.$options.ROLE_AGENT && !row.isPending)?_c('div',{staticClass:"users-table__results",on:{"click":function($event){return _vm.openAgentPlan(row)}}},[_c('el-progress',{attrs:{"type":"circle","percentage":(row.agentProfile && _vm.getPercentage(row.agentProfile.currentPlan, 'show')) || 0,"color":_vm.$options.variables.agentShowsProgressColor,"width":32,"stroke-width":2}}),_c('el-progress',{attrs:{"type":"circle","percentage":(row.agentProfile && _vm.getPercentage(row.agentProfile.currentPlan, 'advert')) || 0,"color":_vm.$options.variables.agentObjectsProgressColor,"width":32,"stroke-width":2}})],1):_vm._e()]}}])})]},proxy:true}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }