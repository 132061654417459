var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bulk-edit-dialog',{staticClass:"user-bulk-edit-dialog",on:{"close":_vm.close,"reset":function($event){return _vm.updateUserEditModel(_vm.user)}},scopedSlots:_vm._u([{key:"itemsCount",fn:function(){return [_vm._v(" "+_vm._s(_vm.isPendingUserEdit ? 'Приглашение' : 'Пользователь')+" ")]},proxy:true},{key:"body",fn:function(){return [_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"user-bulk-edit-dialog__form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИМЯ ПОЛЬЗОВАТЕЛЯ","rules":"required","error-message":_vm.errors.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"type":"text","is-error":!!validationErrors.length},model:{value:(_vm.userEditModel.name),callback:function ($$v) {_vm.$set(_vm.userEditModel, "name", $$v)},expression:"userEditModel.name"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТЕЛЕФОН ПОЛЬЗОВАТЕЛЯ","rules":"required","error-message":_vm.errors.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-phone',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.userEditModel.phone),callback:function ($$v) {_vm.$set(_vm.userEditModel, "phone", $$v)},expression:"userEditModel.phone"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ПОЧТА ПОЛЬЗОВАТЕЛЯ","rules":"required|email","error-message":_vm.errors.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"email"},model:{value:(_vm.userEditModel.email),callback:function ($$v) {_vm.$set(_vm.userEditModel, "email", $$v)},expression:"userEditModel.email"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТИП ПОЛЬЗОВАТЕЛЯ","rules":"required","error-message":_vm.errors.role}},[_c('v-select',{attrs:{"clearable":false,"reduce":function (userType) { return userType.value; },"label":"text","append-to-body":"","options":_vm.$options.USER_TYPES},model:{value:(_vm.userEditModel.role),callback:function ($$v) {_vm.$set(_vm.userEditModel, "role", $$v)},expression:"userEditModel.role"}})],1)],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ДОЛЖНОСТЬ","rules":"required","error-message":_vm.errors.position},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.userEditModel.position),callback:function ($$v) {_vm.$set(_vm.userEditModel, "position", $$v)},expression:"userEditModel.position"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"separate-label":"","label":"ФОТОГРАФИЯ","error-message":_vm.errors.photo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
var validate = ref.validate;
return [_c('v-input-file-image',{attrs:{"with-drop-zone":"","is-error":!!validationErrors.length},on:{"set-file":function($event){return validate($event)}},model:{value:(_vm.userEditModel.photo),callback:function ($$v) {_vm.$set(_vm.userEditModel, "photo", $$v)},expression:"userEditModel.photo"}})]}}],null,true)})],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.isPendingUserEdit)?[_c('v-button',{staticClass:"user-bulk-edit-dialog__form-action",on:{"click":_vm.cancelInvite}},[_vm._v("Отменить приглашение")]),_c('v-button',{staticClass:"user-bulk-edit-dialog__form-action",attrs:{"primary":"","type":"submit"}},[_vm._v("Отправить повторно")])]:[_c('v-button',{staticClass:"user-bulk-edit-dialog__form-action",on:{"click":_vm.close}},[_vm._v("Отменить")]),_c('v-button',{staticClass:"user-bulk-edit-dialog__form-action",attrs:{"primary":"","type":"submit"}},[_vm._v("Обновить")])]]},proxy:true}],null,true)})]}}])})]},proxy:true},(!_vm.isPendingUserEdit)?{key:"footer",fn:function(){return [(_vm.isUsersActive)?_c('v-button',{staticClass:"user-bulk-edit-dialog__action",attrs:{"is-text":""},on:{"click":_vm.archive}},[_vm._v("Архивировать пользователя")]):_c('v-button',{staticClass:"user-bulk-edit-dialog__action",attrs:{"is-text":""},on:{"click":_vm.activate}},[_vm._v("Активировать пользователя")])]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }