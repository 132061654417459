<template>
  <div class="v-input-file-image">
    <div class="v-input-file-image__wrapper">
      <div v-if="localRenderImage" class="v-input-file-image__image-wrapper">
        <div class="v-input-file-image__image">
          <v-image-preview :image="localRenderImage" @remove="removeImage" />
        </div>
      </div>
      <v-input-file-dropzone
        v-if="withDropZone"
        ref="fileInput"
        class="v-input-file-image__dropzone"
        accept="image/jpeg,image/png"
        :is-error="isError"
        :class="[localRenderImage && 'v-input-file-image__hidden']"
        @change="handleFileChange"
      >
        <template #input-text> <slot name="input-text"></slot></template>
        <template #dropzone-text> <slot name="dropzone-text"></slot></template>
      </v-input-file-dropzone>
      <v-input-file
        v-else
        ref="fileInput"
        slot="footer"
        :view="view"
        :is-error="isError"
        accept="image/jpeg,image/png"
        class="v-input-file-image__add"
        :class="[localRenderImage && 'v-input-file-image__hidden']"
        @change="handleFileChange"
      >
        <slot />
      </v-input-file>
    </div>
    <div v-if="localRenderImage" class="v-input-file-image__buttons">
      <v-button is-text type="button" class="v-input-file-image__button" @click="changeImage">Изменить</v-button>
      <v-button is-text type="button" @click="removeImage">Удалить</v-button>
    </div>
  </div>
</template>

<script>
import VInputFile from '@/components/common/VInputFile.vue'
import VImagePreview from '@/components/common/VImagePreview.vue'
import VButton from '@/components/common/VButton.vue'
import VInputFileDropzone from '@/components/common/VInputFileDropzone.vue'

export default {
  name: 'VInputFileImage',
  components: { VInputFileDropzone, VImagePreview, VInputFile, VButton },
  inheritAttrs: false,
  model: {
    prop: 'photo',
    event: 'set-file'
  },
  props: {
    photo: {
      type: [String, File],
      default: ''
    },
    view: {
      type: String,
      default: 'round',
      validate: view => ['round', 'rectangle'].includes(view)
    },
    isError: {
      type: Boolean,
      default: false
    },
    withDropZone: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/jpeg,image/png'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localPhoto: null
    }
  },
  computed: {
    localRenderImage: {
      get() {
        return this.localPhoto || ''
      },
      set(val) {
        URL.revokeObjectURL(this.localPhoto)

        if (val instanceof File) {
          this.localPhoto = URL.createObjectURL(val)
        } else {
          this.localPhoto = val
        }
        this.$emit('set-file', val)
      }
    }
  },
  watch: {
    photo: {
      immediate: true,
      handler(file) {
        this.localRenderImage = file
      }
    }
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.localPhoto)
  },
  methods: {
    handleFileChange(files) {
      ;[this.localRenderImage] = files
    },
    changeImage() {
      if (this.withDropZone) {
        this.$refs.fileInput.$refs.fileInput.$refs.fileInput.click()
      } else {
        this.$refs.fileInput.$refs.fileInput.click()
      }
    },
    removeImage() {
      this.localRenderImage = null
    }
  }
}
</script>
